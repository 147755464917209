<template lang="html">
  <div class="column is-12-mobile is-half-tablet is-one-third-desktop">
    <div class="box">
      <article class="media">
        <div class="media-left">
          <figure class="image is-64x64">
            <img :src="getImgUrl(data.image) || 'project-default-img.jpg'" :alt="data.name + ' logo'" />
          </figure>
        </div>
        <div class="media-content">
          <div class="content">
            <p>
              <strong>{{data.name}}</strong> <small>{{data.date}}</small>
              <br>
              {{data.description}}
            </p>
          </div>
          <div class="tags">
            <span class="tag" :class="tag === 'unpublished' || tag === 'in development' ?  'is-gl-orange' : 'is-gl-green'"
                  v-for="tag in data.tags" v-bind:key="tag">
              {{tag}}
            </span>
          </div>
          <div id="technos">
            <span class="techno is-size-7" v-for="techno in data.technos" v-bind:key="techno">
              #{{techno}}
            </span>
          </div>
          <nav class="level is-mobile">
            <div class="level-left">
              <a v-if="data.type === 'web' && data.url" class="level-item" aria-label="website" :href="data.url" target="_blank">
                <span class="icon is-small">
                  <i class="fas fa-eye" aria-hidden="true"></i>
                </span>
                Visit website
              </a>
              <a v-if="data.type === 'mobile' && data.url" class="level-item" aria-label="store" :href="data.url" target="_blank">
                <span class="icon is-small">
                  <i class="fab fa-microsoft" aria-hidden="true"></i>
                </span>
                Visit store
              </a>
            </div>
          </nav>
        </div>
      </article>
    </div>
  </div>
</template>

<script lang="ts">

export default {
  name: 'Project',
  props: {
    data: Object
  },
  methods: {
    getImgUrl (imageName: string) {
      let imgUrl
      try {
        imgUrl = require('../assets/images/projects/' + imageName)
      } catch (e) {
        imgUrl = require('../assets/images/projects/project-default-img.jpg')
      }
      return imgUrl
    }
  }
}
</script>

<style scoped lang="scss">
.image {
  margin-bottom: 0.5rem;
}

.media-content {
  .content {
    margin-bottom: 1rem;
  }
}

.tags:not(:last-child) {
    margin-bottom: 0;
}

#technos {
  margin-bottom: 1rem;
  .techno:not(:last-child) {
    margin-right: 0.25rem;
  }
}
</style>
