

export default {
  name: 'Project',
  props: {
    data: Object
  },
  methods: {
    getImgUrl (imageName: string) {
      let imgUrl
      try {
        imgUrl = require('../assets/images/projects/' + imageName)
      } catch (e) {
        imgUrl = require('../assets/images/projects/project-default-img.jpg')
      }
      return imgUrl
    }
  }
}
