
import { Options, Vue } from 'vue-class-component'
import Project from '@/components/Project.vue'
import axios from 'axios'
import ProjectClass from '@/models/project'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

@Options({
  components: {
    Project,
    PulseLoader
  }
})
export default class Work extends Vue {
  projects!: ProjectClass[];
  loaded = false;

  data () {
    return {
      projects: [],
      loaded: false,
      spinnerColor: '#1BA1E2'
    }
  }

  mounted () {
    this.loaded = false
    axios.get('projects.json').then(response => {
      console.log(response)
      this.projects = response.data.projects
      this.loaded = true
    })
  }

  orderedItems (items: ProjectClass[]) {
    // Set slice() to avoid to generate an infinite loop!
    return items.slice().sort(function (a, b) {
      return b.date - a.date
    })
  }
}
