<template>
  <div id="work" class="panel">
    <h1 class="title is-spaced is-size-3-desktop is-size-4-mobile">My Recent Side Projects</h1>
    <h2 class="subtitle is-size-5-desktop">Here are a few projects I've worked on recently. Want to see more? <a href="mailto:geoffrey@lalloue.fr">Email me</a>.</h2>
    <div id="projects" class="container is-fluid">
      <pulse-loader :loading="!loaded" :color="spinnerColor"></pulse-loader>
      <div v-if="loaded" class="columns is-multiline is-mobile">
        <Project  v-for="item in orderedItems(projects)" v-bind:key="item"
                  :data="item" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">

#projects {
  text-align: left;
  padding: 2rem 0 4rem;
  .v-spinner {
    text-align: center;
  }
}

@include until($tablet) {
  .projects {
      padding-top: 4rem;
  }
}

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import Project from '@/components/Project.vue'
import axios from 'axios'
import ProjectClass from '@/models/project'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'

@Options({
  components: {
    Project,
    PulseLoader
  }
})
export default class Work extends Vue {
  projects!: ProjectClass[];
  loaded = false;

  data () {
    return {
      projects: [],
      loaded: false,
      spinnerColor: '#1BA1E2'
    }
  }

  mounted () {
    this.loaded = false
    axios.get('projects.json').then(response => {
      console.log(response)
      this.projects = response.data.projects
      this.loaded = true
    })
  }

  orderedItems (items: ProjectClass[]) {
    // Set slice() to avoid to generate an infinite loop!
    return items.slice().sort(function (a, b) {
      return b.date - a.date
    })
  }
}
</script>
